*{
  /* font-family: 'Libre Baskerville', serif; */
font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Libre Baskerville', serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Libre Baskerville', serif;
}


/* ScrollBar Style  */

/* Default styles for all browsers */
/* Hide the scrollbar track */
body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Style the scrollbar thumb */
body::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}

/* Add a hover effect to the scrollbar thumb */
body::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Style the scrollbar corner */
body::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
}

/* Hide the scrollbar track in Firefox */
body {
  scrollbar-color: #555 #f5f5f5;
  scrollbar-width: thin;
}


/* WebKit-based browsers (Chrome, Safari) */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  body::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  body::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
  }
  body::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }
  body::-webkit-scrollbar-corner {
    background-color: #f5f5f5;
  }
}

/* Firefox */
@-moz-document url-prefix() {
  body {
    scrollbar-color: #555 #f5f5f5;
    scrollbar-width: thin;
  }
  body::-moz-scrollbar {
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  body::-moz-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
  }
  body::-moz-scrollbar-thumb:hover {
    background-color: #888;
  }
}
