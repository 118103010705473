/* ProductCard Img Control */
.card-img-control{
    height: 50px !important;
    width: 100% !important;
}

/* Category Wise Nav Style  */

li .nav-link{
    color: gray!important;
}


li .nav-link:hover{
    color: rgb(4, 110, 224) !important;
}
