.custom-category-nav{
  position: sticky ;
  top: 0;
  background-color: #e3f2fd !important;
  z-index: 5;
}
.category-title-icon-control{
    width: 4%;
}
 /* Different CategoryWise Products */
.card-img-control{
  height: 250px;
  }

@media screen and (max-width: 768px) {
  #navbar-example2{
    display: none !important;

  }
}
  
