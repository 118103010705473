

.chatBoxStyle{
    overflow: auto;
    max-height: 550px;
    min-height: 500px;
    
}
.chatBoxStyle::-webkit-scrollbar {
    display: none;
  }