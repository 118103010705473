.float-btn-custom{
    position:fixed;
right: 150px;
bottom: 55px;
border-radius: 50%;
width: 50px;
height: 50px;
border: 1px solid cyan;
background-color: white;
z-index: 120;
}

.top-icon-control{
    width: 100%;
    
}