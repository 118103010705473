.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

/* Custom Styles */

.custom-slider-style{
    width: 300px !important;
    height: 300px !important;
   
}

.custom-pd-it{
    text-align: right;
}
.custom-pd-it{

}
.pd-icon-control{
  height: 24px;
  width: 24px;
  margin-left: 10px;
}

.pd-vl-card{
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}


/* Order Page Style */
.od-icon-control{
  height: 30px;
  width: 30px;
  margin-bottom: 15px;
}
.od-delivery{
  display: inline;
  margin-left: 10px;
  font-size: 24px;
  margin-top: 20px;
}

.od-delivery-options{
  border: 2px solid #cbd7e2;
  border-radius: 10px;
  padding: 10px;
  
}