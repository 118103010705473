.nav-logo{
    width: 30px;
    
}

.custom-nav{
    background-color: #e3f2fd !important;
}
#nav-id{
    border-bottom: 5px solid #209bf2 !important;
}
.nav-user-img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;
}

/* Nav 2 bar --------------- */

.nav2bar-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav2bar-list>li{
    list-style: none;
    margin-right: 5px;
}
.bg-common{

    background-color: #e3f2fd;
}