.btn-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
}

.signup-button :disabled{
    background-color: #e0e0e0;
    color: #9e9e9e;
    cursor: not-allowed;
}