.offcanvas-body ul li{
    list-style: none;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding-left: 15px;
   color: black;
}
.offcanvas-body ul li:hover{
  
    border-bottom: 2px solid rgb(12, 149, 240);
  margin-left: 10px;
  background-color: rgba(129, 200, 248, 0.747);
  

 
   
}
.drawer-icon-control{
    
    width: 7%;
    margin-right: 20px;
}

.link-style-control{
    text-decoration: none;
    
}